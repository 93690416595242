import { fetch as wFetch } from 'whatwg-fetch';

function showMessage(msg) {
  let err_elm = document.getElementById('error-messages');
  let err_con = document.getElementById('error-content');

  err_con.innerText = msg;
  err_elm.classList.add('expand');
}

// sign in function
function submitForm(evt) { //eslint-disable-line no-unused-vars

  evt.preventDefault();
  evt.stopPropagation();

  let finalfetch = window.fetch || wFetch;

  const form = document.getElementById('sign-in-form');
  const formdata = new FormData(form);

  toggleInputsDisabled(true);

  // hacking for ie. formdata will be empty if we dont append a bogus field
  formdata.append('ie', 'fix');

  finalfetch('/auth_user', {
    method: 'POST',
    credentials: 'include',
    body: formdata
  }).then(function(response) {
    if (response.ok) location.reload();
    else {
      switch(response.status) {
        case 401:
          showMessage('Fejl: ugyldig email eller password');
          break;
        default:
          showMessage('Der skete en fejl. Prøv venligst igen');
          break;
      }
      toggleInputsDisabled(false);
    }
  }).catch(function() {
    toggleInputsDisabled(false);
  });

  return false;
}

const form = document.getElementById('sign-in-form');

form.addEventListener('submit', submitForm);

// set the flash message if we have one
let flash = sessionStorage.getItem('flash');
if (flash) {
  sessionStorage.removeItem('flash');
  showMessage(flash);
}

let toggleInputsDisabled = (disable) => {
  let inputs = form.querySelectorAll('input');
  let submit = form.querySelector('button[type=submit]');

  for (let i=0; i<inputs.length; i++) {
    inputs[i].disabled = disable;
  }

  submit.disabled = disable;
  submit.classList.toggle('btn-spinner', disable);
};
